.ant-tabs .ant-card-body,
.ant-no-pads .ant-card-body {
  padding: 0 !important;
}

.ant-input-search {
  margin-bottom: 0;
}

.ant-input-search input,
.ant-input-search>.ant-input-group>.ant-input-group-addon .ant-input-search-button {
  border-radius: 0;
  height: 40px;
}

.ant-table-pagination-right {
  justify-content: center;
}

.gx-layout-sider-dark,
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: #1b2f3a;
  color: #fff;
}

.ant-menu-dark .ant-menu-item>a {
  color: #0099a1;
}

.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item>a:hover,
.ant-menu-dark .ant-menu-item:hover>a,
.ant-menu-dark .ant-menu-item-active>a,
.ant-menu-dark .ant-menu-submenu-active>a,
.ant-menu-dark .ant-menu-submenu-selected>a,
.ant-menu-dark .ant-menu-submenu-title:hover>a,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item>a:hover>a {
  color: #fff !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0099a1;
}

.ant-layout-sider-dark .gx-sidebar-notifications,
.gx-drawer-sidebar-dark .gx-sidebar-notifications {
  border-bottom-color: #0099a1;
}

a,
.gx-link {
  color: #0091aa;
}

.gx-link-rejected {
  color: #E13C39;
}

.gx-text-primary {
  color: #0099a1 !important;
}

.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item>a:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item>a:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before {
  background-color: #fff;
}

.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item>a:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item>a:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before {
  background-color: #fff;
}

.ant-tabs-ink-bar {
  background-color: #0091a1;
}

.full-width {
  width: 100%;
}

.b1 {
  background: #00838f;
}

.b2 {
  background: #0097a7;
}

.b3 {
  background: #00acc1;
}

.b4 {
  background: #00bcd4;
}

.b5 {
  background: #26c6da;
}

.ant-select-item-option-content {
  white-space: pre-wrap !important;
}

.ant-pagination {
  padding: 20px;
  float: right;
}

.marginTopBtn {
  margin-top: 12px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px !important;
  height: 40px;
  padding-top: 3px;
}

.ant-spin {
  margin: 0 !important;
}

.ant-menu-dark .ant-menu-item-group-title {
  color: #fff;
  font-size: 0.81rem;
}

.fex-pincode {
  display: inherit !important;
}

.fex-pincode input {
  border: 0;
  border-bottom: 1px solid #000;
  color: #000;
  height: 40px;
  width: 69px;
  font-size: 18px;
  text-align: center;
  margin-left: 10px;
  outline: none;
}

.button-style {
  gap: 10px;
  display: flex;

}

.accept-button Button {
  color: #fff;
  background-color: #0091aa;
}

.reject-button Button {
  color: #fff;
  background-color: #E13C39;
}

.reject-button Button:hover {
  color: #E13C39;
  background-color: #fff;
  border-color: #E13C39;
}

.reject-button Button:active {
  color: #E13C39;
  background-color: #fff;
  border-color: #E13C39;
}

.reject-button Button:focus {
  color: #E13C39;
  background-color: #fff;
  border-color: #E13C39;
}

.addfiles-button {
  color: #0091aa;
  background-color: #fff;
  border-color: #0091aa;
  border-radius: 10px;
}

.files-section {
  display: flex;
  justify-content: space-between;
}

.card-link-section {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ant-upload-span {
  color: #0091aa;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
}

.acceptmodal-button Button {
  color: #fff;
  background-color: #0091aa;
  border-radius: 10px;
}

.acceptmodal-button Button:hover {
  color: #0091aa;
  background-color: #fff;
  border-color: #0091aa;
}

.acceptmodal-button Button:active {
  color: #0091aa;
  background-color: #fff;
  border-color: #0091aa;
}

.acceptmodal-button Button:focus {
  color: #0091aa;
  background-color: #fff;
  border-color: #0091aa;
}

.rejectmodal-button Button {
  color: #fff;
  background-color: #E13C39;
  border-radius: 10px;
}

.rejectmodal-button Button:hover {
  color: #E13C39;
  background-color: #fff;
  border-color: #E13C39;
}

.rejectmodal-button Button:active {
  color: #E13C39;
  background-color: #fff;
  border-color: #E13C39;
}

.rejectmodal-button Button:focus {
  color: #E13C39;
  background-color: #fff;
  border-color: #E13C39;
}

.accepttypo-text {
  color: #0091aa;
}

.rejecttypo-text {
  color: #E13C39;
}

.referrals-table .ant-dropdown-trigger {
  background-color: blue;
  color: white;
}

.referrals-table tbody tr td:nth-child(7),
.referrals-table tbody tr td:nth-child(9) {
  color: #f3d58b;
}

.ant-modal-header {
  text-align: center !important;
}

.ant-form-item-label>label {
  min-width: 109px;
}

.addchaptermodal .ant-form-item-label>label {
  min-width: 152px;
}

.modalfooterbtnwrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
  padding-top: 17px;
}

.modalfooterbtnwrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 0;

}

.modalfooterbtnwrapper .ant-btn {
  margin-bottom: 0;
}

.ant-form-item-label>label {
  min-width: 109px;
}

.addchaptermodal .ant-form-item-label>label {
  min-width: 152px;
}

.modalfooterbtnwrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
}

.modalfooterbtnwrapper .ant-btn {
  margin-bottom: 0;
}

.hidepostmodal .ant-modal-title {
  text-align: left;
}


/* .checkboxwrapper .ant-checkbox-wrapper {
  max-width:100%;
}
.checkboxwrapper .ant-checkbox-wrapper span
{
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;  max-width: 100%;
}
.checkboxwrapper .ant-checkbox-wrapper .ant-checkbox-inner{
  overflow:unset !important;
} */

.addchapterbtn {
  display: flex;
  justify-content: flex-end;
}

.custom-button-green {
  min-width: 93.7px !important;
  border: none !important;
}

/* when saving admin changes iframe occupied whole screen preventing interactivity */
iframe {
  display: none;
}